@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "logoFont";
  src: url("../public//fonts//Idealist\ Sans.otf") format("truetype"); /* Adjust the path and format as per your font file */
}

@font-face {
  font-family: "logoFontLight";
  src: url("../public//fonts/Idealist\ Sans\ Light.otf") format("truetype"); /* Adjust the path and format as per your font file */
}

@font-face {
  font-family: "robotoBlack";
  src: url("../public/fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "robotoBlackItalic";
  src: url("../public/fonts/Roboto-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "robotoBold";
  src: url("../public/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "robotoBoldItalic";
  src: url("../public/fonts/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "robotoItalic";
  src: url("../public/fonts/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "robotoLight";
  src: url("../public/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "robotoLightItalic";
  src: url("../public/fonts/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "robotoMedium";
  src: url("../public/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "robotoMediumItalic";
  src: url("../public/fonts/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "robotoRegular";
  src: url("../public/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "robotoThin";
  src: url("../public/fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "robotoThinItalic";
  src: url("../public/fonts/Roboto-ThinItalic.ttf") format("truetype");
}

* {
  font-family: robotoRegular;
}
